import { Box, Button, Card, Image, Stack, Text } from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import DatasetImage from '@/assets/datasets/dataset.png'

type EmptyDatasetListProps = {
  isCreateDisabled?: boolean
  onDatasetCreate: () => void
}

export const EmptyDatasetList = ({
  isCreateDisabled,
  onDatasetCreate
}: EmptyDatasetListProps) => {
  return (
    <Card withBorder>
      <Stack align="center" justify="center" mih={400} gap={60}>
        <Image
          w={160}
          h={120}
          fit="cover"
          src={DatasetImage}
          radius="sm"
          styles={(theme) => ({
            root: {
              outline: `1px solid ${theme.colors.dark[1]}`
            }
          })}
        />

        <Box ta="center">
          <Text size="sm" mb="sm">
            <FormattedMessage id="datasets.list.getStarted1" />
          </Text>

          <Text size="sm" fw={600}>
            <FormattedMessage id="datasets.list.getStarted2" />
          </Text>
        </Box>

        <Button
          disabled={isCreateDisabled}
          leftSection={<IconPlus size={16} />}
          onClick={onDatasetCreate}
        >
          <FormattedMessage id="datasets.list.buttons.create" />
        </Button>
      </Stack>
    </Card>
  )
}
