import { Box, Card, SimpleGrid, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { useGetApplications } from '@/queries/applicationQueries'
import { RouterPath, replacePathParams } from '@/router/paths'
import { Application, ApplicationType } from '@/types/app'
import { CrudAction, Resource } from '@/types/permissions'
import { FloatingChatbot } from '../support/FloatingChatbot'
import { VpDemoVideo } from '../support/VpDemoVideo/VpDemoVideo'
import { AddButtonCard } from '../ui-shared/AddButtonCard/AddButtonCard'
import { ErrorWithReload } from '../ui-shared/ErrorWithReload/ErrorWithReload'
import { InfiniteScroll } from '../ui-shared/InfiniteScroll/InfiniteScroll'
import { ApplicationCard } from './ApplicationCard/ApplicationCard'
import { CreateApplicationModal } from './CreateApplicationModal'
import { DeleteApplicationModal } from './DeleteApplicationModal'
import { UpdateApplicationModal } from './UpdateApplicationModal'

export const ApplicationsPage = () => {
  const [appToDelete, setAppToDelete] = useState<Application | null>(null)
  const [appToRename, setAppToRename] = useState<Application | null>(null)

  const [
    isCreateModalOpened,
    { open: openCreateModal, close: closeCreateModal }
  ] = useDisclosure(false)

  const [
    isChatbotModalOpened,
    { open: openChatbotModal, close: closeChatbotModal }
  ] = useDisclosure(false)

  const [canCreateApp, canUpdateApp, canDeleteApp] = useHasPermissions(
    Resource.Applications,
    [CrudAction.Create, CrudAction.Update, CrudAction.Delete]
  )

  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } =
    useGetApplications()

  const pages = data?.pages

  const handleOnReload = () => {
    void refetch()
  }

  const handleLoadMore = () => {
    void fetchNextPage()
  }

  const handleAppDeleted = () => {
    void refetch()
    setAppToDelete(null)
  }

  const handleAppUpdated = () => {
    void refetch()
    setAppToRename(null)
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={handleOnReload}>
        <FormattedMessage id="apps.appList.apiError" />
      </ErrorWithReload>
    )
  }

  return (
    <Box pb={120}>
      <Card mb={100} bg="brand-primary.0" py="xl">
        <VpDemoVideo />
      </Card>

      <Title order={2} mb="lg">
        <FormattedMessage id="apps.myApps" />
      </Title>

      <InfiniteScroll
        hasMore={hasNextPage}
        isFetching={isFetching}
        onLoadMore={handleLoadMore}
      >
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 6 }} spacing="xl">
          {canCreateApp && (
            <AddButtonCard
              height={175}
              label={<FormattedMessage id="apps.create" />}
              onClick={openCreateModal}
            />
          )}

          {pages?.map((page, i) => (
            <Fragment key={i}>
              {page.results.map((app) => (
                <ApplicationCard
                  key={app.id}
                  appName={app.name}
                  appDetailsLink={replacePathParams(RouterPath.appDetails, {
                    appId: app.id
                  })}
                  isMultiStage={
                    app.application_type === ApplicationType.MultiStage
                  }
                  showActions={canUpdateApp || canDeleteApp}
                  showEdit={canUpdateApp}
                  showDelete={canDeleteApp}
                  onDelete={() => setAppToDelete(app)}
                  onRename={() => setAppToRename(app)}
                />
              ))}
            </Fragment>
          ))}
        </SimpleGrid>
      </InfiniteScroll>

      <CreateApplicationModal
        opened={isCreateModalOpened}
        onClose={closeCreateModal}
        onOpenChatbot={openChatbotModal}
      />

      {appToRename && (
        <UpdateApplicationModal
          app={appToRename}
          opened={appToRename !== null}
          onClose={() => setAppToRename(null)}
          onAppUpdated={handleAppUpdated}
        />
      )}

      {appToDelete && (
        <DeleteApplicationModal
          app={appToDelete}
          opened={appToDelete !== null}
          onClose={() => setAppToDelete(null)}
          onAppDeleted={handleAppDeleted}
        />
      )}

      <FloatingChatbot
        opened={isChatbotModalOpened}
        onClose={closeChatbotModal}
      />
    </Box>
  )
}
