import { Select, SelectProps, ThemeIcon } from '@mantine/core'
import { IconCrop, IconPhoto } from '@tabler/icons-react'
import { ReactNode, forwardRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { DatasetType } from '@/types/dataset'
import { SelectOptionWithCheckbox } from './SelectOptionWithCheckbox'

type ConfigFields = {
  name: string
  description: string
  icon: ReactNode
}

const datasetTypeNames: Record<string, ConfigFields> = {
  [DatasetType.Primary]: {
    name: 'datasets.type.primary',
    description: 'datasets.type.primary.description',
    icon: (
      <ThemeIcon variant="light">
        <IconPhoto style={{ width: '80%', height: '80%' }} />
      </ThemeIcon>
    )
  },
  [DatasetType.Secondary]: {
    name: 'datasets.type.secondary',
    description: 'datasets.type.secondary.description',
    icon: (
      <ThemeIcon variant="light">
        <IconCrop style={{ width: '80%', height: '80%' }} />
      </ThemeIcon>
    )
  }
}

export const DatasetTypeSelect = forwardRef<HTMLInputElement, SelectProps>(
  (props, ref) => {
    const intl = useIntl()

    const datasetTypeOptions = Object.entries(datasetTypeNames).map(
      ([type, config]) => ({
        value: type,
        label: intl.formatMessage({
          id: config.name
        })
      })
    )

    return (
      <Select
        ref={ref}
        label={<FormattedMessage id="datasets.type" />}
        maxDropdownHeight={260}
        allowDeselect={false}
        data={datasetTypeOptions}
        renderOption={({ option, checked }) => {
          const { icon, name, description } = datasetTypeNames[option.value]

          return (
            <SelectOptionWithCheckbox
              icon={icon}
              name={<FormattedMessage id={name} />}
              description={<FormattedMessage id={description} />}
              isChecked={checked || false}
            />
          )
        }}
        {...props}
      />
    )
  }
)

DatasetTypeSelect.displayName = 'DatasetTypeSelect'
