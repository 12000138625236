import { ActionIcon, Group } from '@mantine/core'
import {
  IconCaretDownFilled,
  IconCaretUpDownFilled,
  IconCaretUpFilled
} from '@tabler/icons-react'
import { ReactNode, cloneElement } from 'react'
import { SortConfig } from '@/utils/sorting'

const sortIcons = {
  asc: <IconCaretUpFilled />,
  desc: <IconCaretDownFilled />,
  none: <IconCaretUpDownFilled />
}

type SortableLabelProps<T extends SortConfig> = {
  sortKey: T['key']
  sortConfig: T
  label: string | ReactNode
  onSort?: (sortConfig: T) => void
}

export const SortableLabel = <T extends SortConfig>({
  sortKey,
  sortConfig,
  label,
  onSort
}: SortableLabelProps<T>) => {
  const sortOrder = sortConfig.key === sortKey ? sortConfig.order : undefined

  const handleSortClick = () => {
    if (onSort) {
      onSort({
        ...sortConfig,
        key: sortKey,
        order: sortOrder === 'asc' ? 'desc' : 'asc'
      })
    }
  }

  return (
    <Group wrap="nowrap" align="center" gap={2}>
      {label}

      <ActionIcon
        size="sm"
        radius="xl"
        variant="subtle"
        mt={1}
        onClick={handleSortClick}
      >
        {cloneElement(sortIcons[sortOrder || 'none'], {
          style: {
            width: '80%',
            height: '80%'
          }
        })}
      </ActionIcon>
    </Group>
  )
}
