import { Box, Button, Card, Center, Text } from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

type ErrorWithReloadProps = {
  children: ReactNode
  onReload: () => void
}

export const ErrorWithReload = ({
  children,
  onReload
}: ErrorWithReloadProps) => {
  return (
    <Card withBorder>
      <Center mih={240}>
        <Box ta="center">
          <Text size="sm" fw="bold" mb="xl">
            {children}
          </Text>

          <Button size="xs" color="brand-secondary" onClick={onReload}>
            <FormattedMessage id="reload" />
          </Button>
        </Box>
      </Center>
    </Card>
  )
}
