import { ActionIcon, Anchor, Group, Table, Tooltip } from '@mantine/core'
import { IconPencil, IconTrash } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router'
import { LabelGroup } from '@/components/labels/LabelGroup/LabelGroup'
import { SortableLabel } from '@/components/ui-shared/Tables/SortableLabel'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { RouterPath, replacePathParams } from '@/router/paths'
import { Dataset } from '@/types/dataset'
import { CrudAction, Resource } from '@/types/permissions'
import { getFormattedDate } from '@/utils/date'
import { SortConfig } from '@/utils/sorting'
import { DatasetTypeChip } from '../DatasetTypeChip/DatasetTypeChip'
import { DatasetThumbnail } from './DatasetThumbnail'

export type TableSortConfig = SortConfig<'name' | 'created_at'>

type DatasetListProps = {
  datasets: Dataset[]
  sortConfig: TableSortConfig
  onSort: (sortConfig: TableSortConfig) => void
  onPreview: (datasetId: string) => void
  onEdit: (datasetId: string) => void
  onDelete: (datasetId: string) => void
}

export const DatasetList = ({
  datasets,
  sortConfig,
  onSort,
  onPreview,
  onEdit,
  onDelete
}: DatasetListProps) => {
  const location = useLocation()

  const [canUpdateDataset, canDeleteDataset] = useHasPermissions(
    Resource.Datasets,
    [CrudAction.Update, CrudAction.Delete]
  )

  return (
    <Table
      horizontalSpacing="xs"
      verticalSpacing="xs"
      highlightOnHoverColor="gray.0"
      withTableBorder
      highlightOnHover
    >
      <Table.Thead>
        <Table.Tr>
          <Table.Th w={100}>&nbsp;</Table.Th>

          <Table.Th w={240}>
            <SortableLabel
              label={<FormattedMessage id="datasets.list.columns.name" />}
              sortKey="name"
              sortConfig={sortConfig}
              onSort={onSort}
            />
          </Table.Th>

          <Table.Th>
            <FormattedMessage id="datasets.list.columns.type" />
          </Table.Th>

          <Table.Th>
            <FormattedMessage id="datasets.list.columns.fileCount" />
          </Table.Th>

          <Table.Th>
            <SortableLabel
              label={<FormattedMessage id="datasets.list.columns.created" />}
              sortKey="created_at"
              sortConfig={sortConfig}
              onSort={onSort}
            />
          </Table.Th>

          {/* TODO: Re-enable this column once the updated date reflects changes in annotations and files */}
          {/* <Table.Th>
            <FormattedMessage id="datasets.list.columns.updated" />
          </Table.Th> */}

          <Table.Th w={300}>
            <FormattedMessage id="datasets.list.columns.labelingClasses" />
          </Table.Th>

          <Table.Th>&nbsp;</Table.Th>
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        {datasets.map((dataset) => (
          <Table.Tr key={dataset.id}>
            <Table.Td>
              <DatasetThumbnail
                imageUrl={dataset.thumbnail || undefined}
                onPreview={() => onPreview(dataset.id)}
              />
            </Table.Td>

            <Table.Td>
              <Anchor
                component={Link}
                size="sm"
                underline="hover"
                c="blue.8"
                fw="bold"
                to={replacePathParams(RouterPath.datasetDetails, {
                  datasetId: dataset.id
                })}
                state={{ datasetListParams: location.search }}
              >
                {dataset.name}
              </Anchor>
            </Table.Td>

            <Table.Td>
              <DatasetTypeChip datasetType={dataset.dataset_type} />
            </Table.Td>

            <Table.Td>
              <FormattedMessage
                id="datasets.fileCount"
                values={{ count: dataset.media_files_count }}
              />
            </Table.Td>

            <Table.Td>
              {getFormattedDate(dataset.created_at, {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              })}
            </Table.Td>

            {/* <Table.Td>
              {getFormattedDate(dataset.updated_at, {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              })}
            </Table.Td> */}

            <Table.Td>
              <LabelGroup labels={dataset.labels} maxLabelsShown={5} />
            </Table.Td>

            <Table.Td>
              <Group wrap="nowrap" gap={4} justify="end">
                {canUpdateDataset && (
                  <Tooltip
                    label={<FormattedMessage id="datasets.list.buttons.edit" />}
                  >
                    <ActionIcon
                      variant="subtle"
                      size="sm"
                      radius="xl"
                      onClick={() => onEdit(dataset.id)}
                    >
                      <IconPencil style={{ width: '80%', height: '80%' }} />
                    </ActionIcon>
                  </Tooltip>
                )}

                {canDeleteDataset && (
                  <Tooltip
                    label={
                      <FormattedMessage id="datasets.list.buttons.delete" />
                    }
                  >
                    <ActionIcon
                      variant="subtle"
                      size="sm"
                      radius="xl"
                      onClick={() => onDelete(dataset.id)}
                    >
                      <IconTrash style={{ width: '70%', height: '70%' }} />
                    </ActionIcon>
                  </Tooltip>
                )}
              </Group>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
}
