import { Box, Button, Card, Group } from '@mantine/core'
import { FileRejection } from '@mantine/dropzone'
import { useDisclosure } from '@mantine/hooks'
import { IconBolt } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { AnnotationProgressBar } from '@/components/annotations/AnnotationProgressBar/AnnotationProgressBar'
import { getAnnotationPoints } from '@/components/annotations/helpers/getAnnotationPoints'
import { MIN_ANNOTATION_POINTS } from '@/config/annotation'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import {
  useCreateDatasetVersion,
  useGetAnnotationCount,
  useGetUploadStatus,
  useInvalidateFileList,
  useUploadFilesToDataset
} from '@/queries/datasetQueries'
import { showToast } from '@/theme/notifications'
import { DatasetVersion, DatasetVersionStatus } from '@/types/dataset'
import { CrudAction, Resource } from '@/types/permissions'
import { CreateDatasetVersionBanner } from '../CreateDatasetVersionBanner/CreateDatasetVersionBanner'
import { ConfirmUploadModal } from './ConfirmUploadModal/ConfirmUploadModal'
import { UploadProgressModal } from './UploadProgressModal/UploadProgressModal'
import { UploadableMediaList } from './UploadableMediaList'

type UploadableMediaListHandlerProps = {
  version: DatasetVersion
  showTrainModelButton?: boolean
  onVersionCreated: (newVersion: DatasetVersion) => void
  onTrainModel?: () => void
}

export const UploadableMediaListHandler = ({
  version,
  showTrainModelButton,
  onVersionCreated,
  onTrainModel
}: UploadableMediaListHandlerProps) => {
  const intl = useIntl()
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])
  const [uploadBatchId, setUploadBatchId] = useState<string>('')
  const [
    isConfirmModalOpened,
    { open: openConfirmModal, close: closeConfirmModal }
  ] = useDisclosure(false)

  const [
    isProgressModalOpened,
    { open: openProgressModal, close: closeProgressModal }
  ] = useDisclosure(false)

  const [canCreateVersion, canUploadFiles] = useHasPermissions(
    Resource.Datasets,
    [CrudAction.Create, CrudAction.Update]
  )

  const [canCreateTraining] = useHasPermissions(Resource.Trainings, [
    CrudAction.Create
  ])

  const {
    mutateAsync: createDatasetVersion,
    isPending: isCreateVersionPending
  } = useCreateDatasetVersion()

  const { mutateAsync: uploadFiles, isPending: isUploadPending } =
    useUploadFilesToDataset()

  const { data: uploadStatusData } = useGetUploadStatus(
    uploadBatchId,
    uploadBatchId !== ''
  )

  const isUploadFinished =
    uploadStatusData?.status === 'Complete' ||
    uploadStatusData?.status === 'Failed'

  const { invalidateFileList } = useInvalidateFileList(version.id)

  const { application } = useApplicationContext()
  const { model } = useModelContext()

  const { data: annotationCount } = useGetAnnotationCount(version.id)

  const annotatedImagesCount = annotationCount?.images || 0
  const annotatedVideosCount = annotationCount?.videos || 0

  const isTrainingButtonDisabled =
    getAnnotationPoints(annotatedImagesCount, annotatedVideosCount) <
      MIN_ANNOTATION_POINTS || !canCreateTraining

  useEffect(() => {
    if (
      uploadStatusData?.uploaded_files !== 0 &&
      uploadStatusData?.status === 'Pending'
    ) {
      // Refetch file list as more files were uploaded
      invalidateFileList()
    }

    if (isUploadFinished) {
      invalidateFileList()

      if (uploadStatusData?.failed_files > 0) {
        openProgressModal()

        showToast(
          intl.formatMessage(
            { id: 'datasets.upload.filesPartiallyUploaded' },
            { count: uploadStatusData?.failed_files }
          ),
          'error'
        )
      } else {
        setUploadBatchId('')
        closeProgressModal()

        showToast(
          intl.formatMessage({ id: 'datasets.upload.completed' }),
          'success'
        )
      }
    }
  }, [
    intl,
    uploadStatusData,
    isUploadFinished,
    openProgressModal,
    closeProgressModal,
    invalidateFileList
  ])

  const handleDrop = (files: File[]) => {
    setAcceptedFiles(files)
    openConfirmModal()
  }

  const handleReject = (fileRejections: FileRejection[]) => {
    setFileRejections(fileRejections)
    openConfirmModal()
  }

  const handleCloseConfirmModal = () => {
    closeConfirmModal()
    setAcceptedFiles([])
    setFileRejections([])
  }

  const handleCreateNewVersion = async () => {
    try {
      const newVersion = await createDatasetVersion({
        datasetId: version.dataset,
        parentVersionId: version.id
      })

      onVersionCreated(newVersion)
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage ||
        intl.formatMessage({ id: 'datasets.newVersion.create.error' })

      showToast(message, 'error')
    }
  }

  const handleConfirmUpload = async () => {
    try {
      const notification_context = {
        appId: application?.id || '',
        modelId: model?.id || ''
      }
      const { data } = await uploadFiles({
        versionId: version.id,
        media_files: acceptedFiles,
        notification_context: notification_context
      })

      setUploadBatchId(data.batch_id)
      closeConfirmModal()
      openProgressModal()
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'datasets.upload.failed' })

      showToast(message, 'error')
    }
  }

  const handleCloseProgressModal = () => {
    if (isUploadFinished) {
      setUploadBatchId('')
    }

    closeProgressModal()
  }

  return (
    <>
      {version.status === DatasetVersionStatus.Completed && (
        <Card bg="brand-primary.0" mb="sm">
          <CreateDatasetVersionBanner
            isPending={isCreateVersionPending}
            showCreateButton={canCreateVersion}
            onCreateVersion={() => void handleCreateNewVersion()}
          />
        </Card>
      )}

      <UploadableMediaList
        versionId={version.id}
        isUploading={uploadStatusData?.status === 'Pending'}
        isLocked={
          version.status === DatasetVersionStatus.Completed || !canUploadFiles
        }
        rightSection={
          <Group>
            {annotationCount !== undefined && (
              <Box w={300}>
                <AnnotationProgressBar
                  annotatedImagesCount={annotatedImagesCount}
                  annotatedVideosCount={annotatedVideosCount}
                />
              </Box>
            )}

            {showTrainModelButton && (
              <Button
                leftSection={<IconBolt size={16} />}
                miw={180}
                color="green"
                disabled={isTrainingButtonDisabled}
                onClick={onTrainModel}
              >
                <FormattedMessage id="models.trainModel" />
              </Button>
            )}
          </Group>
        }
        onDrop={handleDrop}
        onReject={handleReject}
        onShowUploadProgress={openProgressModal}
      />

      <ConfirmUploadModal
        opened={isConfirmModalOpened}
        acceptedFiles={acceptedFiles}
        fileRejections={fileRejections}
        isLoading={isUploadPending}
        isConfirmDisabled={isUploadPending || acceptedFiles.length === 0}
        onClose={handleCloseConfirmModal}
        onConfirmUpload={() => void handleConfirmUpload()}
      />

      <UploadProgressModal
        totalFiles={uploadStatusData?.total_files || 0}
        uploadedFies={uploadStatusData?.uploaded_files || 0}
        isCompleted={isUploadFinished}
        failedFileNames={uploadStatusData?.failed_file_names || []}
        opened={isProgressModalOpened}
        onClose={handleCloseProgressModal}
      />
    </>
  )
}
