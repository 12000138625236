import { AppShell, Box, Divider, ScrollArea, Stack } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { PropsWithChildren, ReactNode } from 'react'
import { Chatbot } from '@/components/support/Chatbot'
import { FeedbackButton } from '@/components/support/FeedbackButton/FeedbackButton'
import { HEADER_HEIGHT, Header } from '../Header/Header'

const NAVBAR_WIDTH = 310

type BaseLayoutProps = PropsWithChildren<{
  navSection: ReactNode
  navSectionMinHeight?: number
}>

export const BaseLayout = ({
  children,
  navSection,
  navSectionMinHeight = 200
}: BaseLayoutProps) => {
  const [opened, { toggle }] = useDisclosure()

  return (
    <AppShell
      header={{
        height: HEADER_HEIGHT
      }}
      navbar={{
        width: NAVBAR_WIDTH,
        breakpoint: 'sm',
        collapsed: { mobile: !opened }
      }}
    >
      <AppShell.Header px="md" bg="brand-primary">
        <Header isMenuOpen={opened} onBurgerMenuClick={toggle} />
      </AppShell.Header>

      <AppShell.Navbar>
        <AppShell.Section component={ScrollArea} grow>
          <Box mb={40} mih={navSectionMinHeight}>
            {navSection}
          </Box>

          <Box px={2}>
            <Chatbot />
          </Box>
        </AppShell.Section>

        <Divider />

        <AppShell.Section>
          <Stack p="md" gap={2}>
            <FeedbackButton />
            {/* add more support buttons here */}
          </Stack>
        </AppShell.Section>
      </AppShell.Navbar>

      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  )
}
