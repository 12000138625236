import { Button, Drawer, Group, Skeleton, Stack } from '@mantine/core'
import { useDisclosure, useViewportSize } from '@mantine/hooks'
import { IconMessage, IconX } from '@tabler/icons-react'
import { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { showToast } from '@/theme/notifications'

type ZohoIframeMessage = {
  zohosurvey_respondentStatus?: string
}

// embedded via iframe
const FEEDBACK_FORM_URL = 'https://survey.zohopublic.eu/zs/sXTGGp'

export const FeedbackButton = () => {
  const intl = useIntl()
  const [iframeLoaded, setIframeLoaded] = useState(false)

  const [
    isFeedbackFormOpened,
    { open: openFeedbackForm, close: closeFeedbackForm }
  ] = useDisclosure(false)

  const { height } = useViewportSize()

  const resetAndClose = useCallback(() => {
    setIframeLoaded(false)
    closeFeedbackForm()
  }, [closeFeedbackForm])

  // https://help.zoho.com/portal/en/kb/survey/launch/website-insert/articles/website-insert#To_get_notified_once_a_survey_is_submitted
  useEffect(() => {
    const handleMessage = (message: MessageEvent<ZohoIframeMessage>) => {
      if (message.data?.zohosurvey_respondentStatus === 'completed') {
        resetAndClose()

        showToast(
          intl.formatMessage({ id: 'general.feedback.success' }),
          'success'
        )
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [resetAndClose, intl])

  return (
    <>
      <Button
        color="brand-secondary"
        size="sm"
        leftSection={<IconMessage size={20} />}
        fullWidth
        onClick={openFeedbackForm}
      >
        <FormattedMessage id="general.feedback.button" />
      </Button>

      <Drawer.Root
        size="60rem"
        position="right"
        opened={isFeedbackFormOpened}
        onClose={resetAndClose}
      >
        <Drawer.Overlay />

        <Drawer.Content>
          <Drawer.Header>
            <Group wrap="nowrap" align="center" gap="xs">
              <IconMessage size={24} />

              <Drawer.Title>
                <FormattedMessage id="general.feedback.button" />
              </Drawer.Title>
            </Group>

            <Button
              miw={100}
              size="xs"
              leftSection={<IconX size={14} />}
              onClick={resetAndClose}
            >
              <FormattedMessage id="close" />
            </Button>
          </Drawer.Header>

          <Drawer.Body>
            {!iframeLoaded && (
              <Stack p="xl" gap="lg">
                <Skeleton height={24} width={320} />
                <Skeleton height={24} width={320} />
                <Skeleton height={32} width={480} />
                <Skeleton height={32} width={480} />
                <Skeleton height={48} width={600} />
                <Skeleton height={48} width={600} />
              </Stack>
            )}

            <iframe
              src={FEEDBACK_FORM_URL}
              style={{
                width: '100%',
                height: height - 100,
                border: 'none',
                display: iframeLoaded ? 'block' : 'none'
              }}
              onLoad={() => setIframeLoaded(true)}
            />
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    </>
  )
}
