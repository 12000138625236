import { NavLink, rem } from '@mantine/core'
import { IconBox, IconFolders } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router'
import { RouterPath } from '@/router/paths'

export const HomeNavLinks = () => {
  const { pathname } = useLocation()

  return (
    <>
      <NavLink
        component={Link}
        to={RouterPath.apps}
        label={<FormattedMessage id="navLinks.applications" />}
        leftSection={<IconBox size={18} />}
        variant="light"
        color="brand-secondary"
        styles={{
          label: {
            fontSize: rem(15),
            fontWeight: 600
          }
        }}
        active={pathname.includes(RouterPath.apps)}
      />

      <NavLink
        component={Link}
        to={RouterPath.datasets}
        label={<FormattedMessage id="navLinks.datasets" />}
        leftSection={<IconFolders size={18} />}
        variant="light"
        color="brand-secondary"
        styles={{
          label: {
            fontSize: rem(15),
            fontWeight: 600
          }
        }}
        active={pathname.includes(RouterPath.datasets)}
      />
    </>
  )
}
