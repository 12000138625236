import { Badge, Group, HoverCard } from '@mantine/core'
import { Label } from '@/types/label'

type LabelGroupProps = {
  labels: Label[]
  maxLabelsShown?: number
}

export const LabelGroup = ({ labels, maxLabelsShown }: LabelGroupProps) => {
  const labelCount = labels.length

  return (
    <Group gap="xs">
      {labels.slice(0, maxLabelsShown).map((item) => (
        <Badge key={item.name} size="sm" color={item.color}>
          {item.name}
        </Badge>
      ))}

      {maxLabelsShown && labelCount > maxLabelsShown && (
        <HoverCard width={280} shadow="md">
          <HoverCard.Target>
            <Badge size="sm" variant="light">
              + {labelCount - maxLabelsShown}
            </Badge>
          </HoverCard.Target>

          <HoverCard.Dropdown>
            <Group gap="xs">
              {labels.slice(maxLabelsShown).map((item) => (
                <Badge key={item.name} size="sm" color={item.color}>
                  {item.name}
                </Badge>
              ))}
            </Group>
          </HoverCard.Dropdown>
        </HoverCard>
      )}
    </Group>
  )
}
