import { Button, Card, Center, SimpleGrid, Tooltip } from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { useGetDatasetsInfinite } from '@/queries/datasetQueries'
import { Dataset, DatasetType } from '@/types/dataset'
import { CrudAction, Resource } from '@/types/permissions'
import { InfiniteScroll } from '../../ui-shared/InfiniteScroll/InfiniteScroll'
import { DatasetCard } from '../DatasetCard/DatasetCard'

type CreateButtonProps = {
  onClick: () => void
  withMargin?: boolean
}

const CreateButton = ({ onClick, withMargin }: CreateButtonProps) => {
  return (
    <Button
      size="sm"
      variant="default"
      mb={withMargin ? 'lg' : 0}
      leftSection={<IconPlus size={16} />}
      onClick={onClick}
    >
      <FormattedMessage id="datasets.create" />
    </Button>
  )
}

type SelectableDatasetListProps = {
  initialDatasetId?: string
  selectedDatasetId: string
  selectableDatasetType: DatasetType
  onPreviewDataset: (dataset: Dataset) => void
  onSelectDataset: (dataset: Dataset) => void
  onCreateDataset: () => void
  onResetSelection: () => void
}

export const SelectableDatasetList = ({
  initialDatasetId,
  selectedDatasetId,
  selectableDatasetType,
  onPreviewDataset,
  onSelectDataset,
  onCreateDataset,
  onResetSelection
}: SelectableDatasetListProps) => {
  const [canCreateDataset] = useHasPermissions(Resource.Datasets, [
    CrudAction.Create
  ])

  const {
    data,
    isLoading,
    isFetching,
    isError,
    refetch,
    fetchNextPage,
    hasNextPage
  } = useGetDatasetsInfinite({
    is_library: false,
    ordering: 'created_at'
  })

  const datasets = data?.pages?.flatMap((page) => page.results) || []

  const initialDataset = datasets.find(
    (dataset) => dataset.id === initialDatasetId
  )

  useEffect(() => {
    if (initialDataset) {
      onSelectDataset(initialDataset)
    }
  }, [initialDataset, onSelectDataset])

  const toggleSelection = (dataset: Dataset) => {
    if (selectedDatasetId === dataset.id) {
      onResetSelection()
    } else {
      onSelectDataset(dataset)
    }
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={() => void refetch()}>
        <FormattedMessage id="datasets.list.error" />
      </ErrorWithReload>
    )
  }

  return (
    <>
      {datasets.length === 0 && !isLoading && canCreateDataset && (
        <Card withBorder>
          <Center h={200}>
            <CreateButton onClick={onCreateDataset} />
          </Center>
        </Card>
      )}

      {datasets.length > 0 && canCreateDataset && (
        <CreateButton withMargin onClick={onCreateDataset} />
      )}

      <InfiniteScroll
        hasMore={hasNextPage}
        isFetching={isFetching}
        onLoadMore={() => void fetchNextPage()}
      >
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 7 }} spacing="xl">
          {datasets.map((dataset) => (
            <Tooltip
              key={dataset.id}
              label={
                selectableDatasetType === DatasetType.Primary ? (
                  <FormattedMessage id="datasets.secondary.disabledHint" />
                ) : (
                  <FormattedMessage id="datasets.primary.disabledHint" />
                )
              }
              disabled={dataset.dataset_type === selectableDatasetType}
            >
              <div>
                <DatasetCard
                  name={dataset.name}
                  fileCount={dataset.media_files_count}
                  datasetType={dataset.dataset_type}
                  isSelected={selectedDatasetId === dataset.id}
                  disabled={dataset.dataset_type !== selectableDatasetType}
                  thumbnailUrl={dataset.thumbnail || undefined}
                  onPreview={() => onPreviewDataset(dataset)}
                  onSelect={() => toggleSelection(dataset)}
                />
              </div>
            </Tooltip>
          ))}
        </SimpleGrid>
      </InfiniteScroll>
    </>
  )
}
