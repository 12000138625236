/* eslint-disable react/prop-types */
import { ActionIcon, Badge, Box, Button, Group, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconTrash } from '@tabler/icons-react'
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable
} from 'mantine-react-table'
import {
  Dispatch,
  SetStateAction,
  UIEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Event } from '@/types/event'
import { extractRoiNames } from '../EventDetail/extractRoiNames'
import { DeleteEventModal } from './DeleteEventModal'
import { DownloadSelectedEventsButton } from './DownloadAs/DownloadSelectedEventsButton'
import { DownloadToolbarIcon } from './DownloadAs/DownloadToolbarIcon'
import { useDownloadAs } from './DownloadAs/useDownloadAs'
import { EventNoteHandler } from './EventNoteHandler'
import { EventThumbnail } from './EventThumbnail'

type FilterOption = {
  label: string
  value: string
}

export type EventsFilterOptions = {
  roiNames: FilterOption[]
  detections: FilterOption[]
  devices: FilterOption[]
}

type EventsTableProps = {
  events: Event[]
  totalEvents: number
  applicationId: string
  isFetching: boolean
  sorting: MRT_SortingState
  columnFilters: MRT_ColumnFiltersState
  filterOptions: EventsFilterOptions
  showDeleteButton?: boolean
  showDownloadButton?: boolean
  showEventNote?: boolean
  fetchNextPage: () => void
  refetch: () => void
  onSortingChange: Dispatch<SetStateAction<MRT_SortingState>>
  onFilterChange: Dispatch<SetStateAction<MRT_ColumnFiltersState>>
  onRowClick: (eventId: string) => void
}

export const EventsTable = ({
  events,
  totalEvents,
  applicationId,
  isFetching,
  sorting,
  columnFilters,
  filterOptions,
  showDeleteButton,
  showDownloadButton,
  showEventNote,
  fetchNextPage,
  refetch,
  onSortingChange,
  onFilterChange,
  onRowClick
}: EventsTableProps) => {
  const intl = useIntl()
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})
  const [deleteEventId, setDeleteEventId] = useState<string | null>(null)

  const [
    isDeleteModalOpened,
    { open: openDeleteModal, close: closeDeleteModal }
  ] = useDisclosure(false)

  const { isDownloadPending, handleDownloadAs } = useDownloadAs({
    appId: applicationId
  })

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement

        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 400 &&
          !isFetching &&
          events.length < totalEvents
        ) {
          fetchNextPage()
        }
      }
    },
    [fetchNextPage, isFetching, events, totalEvents]
  )

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])

  const hasMeasurmentEvents = events.some((event) => event.measurement)

  const columns = useMemo<MRT_ColumnDef<Event>[]>(
    () => [
      {
        accessorFn: (row) => {
          return row.recordings[0]?.thumbnail_path || ''
        },
        Cell: ({ cell }) => {
          const path = cell.getValue<string>()
          return <EventThumbnail path={path} />
        },
        header: '',
        id: 'thumbnail',
        enableSorting: false,
        enableColumnFilter: false
      },
      {
        accessorFn: (row) => {
          const stringDate = new Date(row.timestamp?.$date)
          return stringDate.toLocaleString()
        },
        header: intl.formatMessage({ id: 'events.list.capturedOn' }),
        id: 'timestamp',
        filterVariant: 'date-range',
        mantineFilterDateInputProps: {
          valueFormat: 'MMM D, YYYY'
        },
        size: 320
      },
      {
        accessorFn: (row) => {
          return row.metadata?.device?.label || ''
        },
        enableSorting: false,
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: filterOptions.devices
        },
        header: intl.formatMessage({ id: 'events.list.device' }),
        id: 'metadata.device_id'
      },
      {
        accessorFn: (row) => {
          return row.metadata?.camera?.name || ''
        },
        enableSorting: false,
        enableColumnFilter: false,
        header: intl.formatMessage({ id: 'events.list.camera' }),
        id: 'metadata.camera_id'
      },
      {
        accessorFn: (row) => {
          const detections = row.detections.split('->')
          return [detections[detections.length - 1]]
        },
        Cell: ({ cell }) => {
          const items = cell.getValue<string[]>()
          return (
            <Group gap="sm">
              {items.map((cell) => (
                <Badge key={cell}>{cell}</Badge>
              ))}
            </Group>
          )
        },
        header: intl.formatMessage({ id: 'events.detections' }),
        id: 'detections',
        enableSorting: false,
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: filterOptions.detections
        }
      },
      {
        accessorFn: (row) => {
          return extractRoiNames(row)
        },
        header: intl.formatMessage({ id: 'events.list.roi' }),
        id: 'roiName',
        enableSorting: false,
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: filterOptions.roiNames
        }
      },
      {
        accessorFn: (row) => {
          if ('confidence' in row) {
            return row.confidence.toFixed(2)
          }

          return 'N/A'
        },
        header: intl.formatMessage({ id: 'events.confidence' }),
        id: 'confidence',
        enableSorting: false,
        enableColumnFilter: false
      },
      ...(hasMeasurmentEvents
        ? ([
            {
              accessorFn: (row) => {
                return row.measurement?.id || ''
              },
              header: intl.formatMessage({ id: 'events.measurement.id' }),
              id: 'objectId',
              enableSorting: false,
              enableColumnFilter: false
            },
            {
              accessorFn: (row) => {
                if (!row.measurement) {
                  return ''
                }

                const { width, height, depth, unit } =
                  row.measurement.dimensions

                return `${depth} x ${width} x ${height} ${unit}`
              },
              header: intl.formatMessage({ id: 'events.measurement.size' }),
              id: 'size',
              enableSorting: false,
              enableColumnFilter: false
            },
            {
              accessorFn: (row) => {
                if (!row.measurement) {
                  return ''
                }

                const { value, unit } = row.measurement.weight

                return `${value} ${unit}`
              },
              header: intl.formatMessage({ id: 'events.measurement.weight' }),
              id: 'weight',
              enableSorting: false,
              enableColumnFilter: false,
              maxSize: 120
            }
          ] as MRT_ColumnDef<Event>[])
        : [])
    ],
    [intl, hasMeasurmentEvents, filterOptions]
  )

  const selectedEventIds = Object.keys(rowSelection)

  const renderRowActions = (row: MRT_Row<Event>) => {
    return (
      <Group wrap="nowrap" gap={4}>
        {showEventNote && (
          <EventNoteHandler
            applicationId={applicationId}
            eventId={row.id}
            hasNote={row.original.metadata?.note !== ''}
            noteText={row.original.metadata?.note || ''}
            onNoteSave={refetch}
          />
        )}

        {showDeleteButton && (
          <ActionIcon
            variant="subtle"
            size="md"
            color="red"
            onClick={(event) => {
              event.stopPropagation()
              setDeleteEventId(row.id)
              openDeleteModal()
            }}
          >
            <IconTrash style={{ width: '70%', height: '70%' }} />
          </ActionIcon>
        )}
      </Group>
    )
  }

  const renderTopToolbarCustomActions = () => {
    return (
      <Group>
        {showDownloadButton && (
          <DownloadSelectedEventsButton
            eventIds={selectedEventIds}
            isDownloadPending={isDownloadPending}
            onDownloadAs={(params) => void handleDownloadAs(params)}
          />
        )}

        {showDeleteButton && (
          <Button
            leftSection={<IconTrash size={16} />}
            color="red"
            disabled={selectedEventIds.length === 0}
            onClick={openDeleteModal}
          >
            <Text size="sm">
              <FormattedMessage id="events.list.deleteSelection" />
            </Text>
          </Button>
        )}
      </Group>
    )
  }

  const renderToolbarInternalActions = () => {
    return (
      showDownloadButton && (
        <DownloadToolbarIcon
          isDownloadPending={isDownloadPending}
          onDownloadClick={(params) => void handleDownloadAs(params)}
        />
      )
    )
  }

  const renderBottomToolbarCustomActions = () => {
    return (
      <Box py="sm">
        <Text size="sm">
          <FormattedMessage id="events.count" values={{ count: totalEvents }} />
        </Text>
      </Box>
    )
  }

  const table = useMantineReactTable({
    columns,
    data: events,
    enablePagination: false,
    enableRowVirtualization: true,
    manualSorting: true,
    manualFiltering: true,
    enableColumnActions: false,
    enableRowSelection: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    mantineProgressProps: ({ isTopToolbar }) => {
      return {
        styles: {
          root: {
            display: isTopToolbar ? 'block' : 'none'
          }
        },
        color: 'brand-primary',
        size: 'sm',
        value: 100
      }
    },

    mantineTableContainerProps: {
      ref: tableContainerRef, // get access to the table container element
      style: { height: '65vh', maxHeight: '65vh' }, // give the table a max height
      onScroll: (
        event: UIEvent<HTMLDivElement> // add an event listener to the table container element
      ) => fetchMoreOnBottomReached(event.target as HTMLDivElement)
    },

    state: {
      showProgressBars: isFetching,
      rowSelection,
      sorting,
      columnFilters,
      showColumnFilters: true
    },

    onSortingChange,
    onColumnFiltersChange: onFilterChange,

    renderRowActions: ({ row }) => renderRowActions(row),
    getRowId: (row) => row.id,
    onRowSelectionChange: setRowSelection,

    renderTopToolbarCustomActions,
    renderToolbarInternalActions,
    renderBottomToolbarCustomActions,

    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => onRowClick(row.id),

      style: {
        cursor: 'pointer'
      }
    })
  })

  const handleEventDeleted = () => {
    table.resetRowSelection()
    refetch()
  }

  const handleDeleteModalClose = () => {
    closeDeleteModal()
    setDeleteEventId(null)
  }

  return (
    <>
      <MantineReactTable table={table} />

      <DeleteEventModal
        appId={applicationId}
        eventIds={deleteEventId ? [deleteEventId] : selectedEventIds}
        opened={isDeleteModalOpened}
        onEventDeleted={handleEventDeleted}
        onClose={handleDeleteModalClose}
      />
    </>
  )
}
