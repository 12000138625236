import { Anchor } from '@mantine/core'
import { ReactNode } from 'react'
import { Link, LinkProps } from 'react-router'

type BreadcrumbLinkProps = LinkProps & {
  label: string | ReactNode
}

export const BreadcrumbLink = ({ label, ...props }: BreadcrumbLinkProps) => {
  return (
    <Anchor underline="hover" c="blue.8" component={Link} {...props}>
      {label}
    </Anchor>
  )
}
