import { Container, Paper, ScrollArea, rem } from '@mantine/core'
import { ReactNode } from 'react'
import { HEADER_HEIGHT } from '../Header/Header'
import {
  PAGE_MAX_WIDTH,
  PAGE_PADDING_X,
  PageContainer
} from '../PageContainer/PageContainer'

const TOOLBAR_HEIGHT = 62
const TOOLBAR_SPACING = 4

type PageWithFixedToolbarProps = {
  toolbar: ReactNode
  children: ReactNode
  toolbarColor?: string
}

export const PageWithFixedToolbar = ({
  toolbar,
  children,
  toolbarColor = 'white'
}: PageWithFixedToolbarProps) => {
  return (
    <>
      <Paper
        h={TOOLBAR_HEIGHT}
        radius={0}
        shadow="sm"
        mb={TOOLBAR_SPACING}
        bg={toolbarColor}
        styles={{
          root: {
            borderWidth: '0 0 1px 0',
            borderColor: 'var(--mantine-color-gray-4)'
          }
        }}
        withBorder
      >
        <Container h="100%" px={PAGE_PADDING_X} size={rem(PAGE_MAX_WIDTH)}>
          {toolbar}
        </Container>
      </Paper>

      <ScrollArea
        h={`calc(100dvh - ${rem(TOOLBAR_HEIGHT + HEADER_HEIGHT + TOOLBAR_SPACING)})`}
      >
        <PageContainer py="md">{children}</PageContainer>
      </ScrollArea>
    </>
  )
}
