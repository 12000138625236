import { Box, LoadingOverlay, Modal } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import {
  useGetDatasetDetails,
  useUpdateDataset
} from '@/queries/datasetQueries'
import { showToast } from '@/theme/notifications'
import { DatasetForm, FormValues } from '../DatasetForm/DatasetForm'

type UpdateDatasetModalProps = {
  datasetId: string
  opened: boolean
  onClose: () => void
  onDatasetUpdated: () => void
}

export const UpdateDatasetModal = ({
  datasetId,
  opened,
  onClose,
  onDatasetUpdated
}: UpdateDatasetModalProps) => {
  const intl = useIntl()

  const { data: dataset, isLoading } = useGetDatasetDetails(datasetId, opened)

  const hasFiles = (dataset?.media_files_count ?? 0) > 0

  const { mutateAsync: updateDataset, isPending } = useUpdateDataset(datasetId)

  const handleDatasetUpdate = async (values: FormValues) => {
    const { name, description, datasetType, labelingType, classes } = values

    try {
      await updateDataset({
        datasetId,
        data: {
          name,
          description,
          dataset_type: datasetType,
          ...(hasFiles
            ? {}
            : {
                labeling_type: labelingType,
                labels: classes.map((labelClass) => ({
                  name: labelClass.name,
                  color: labelClass.color
                }))
              })
        }
      })

      showToast(
        intl.formatMessage({ id: 'datasets.update.success' }),
        'success'
      )

      onDatasetUpdated()
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'datasets.update.error' })

      showToast(message, 'error')
    }
  }

  return (
    <Modal
      title={<FormattedMessage id="datasets.update" />}
      size="lg"
      opened={opened}
      onClose={onClose}
    >
      <Box pos="relative" mih={300}>
        <LoadingOverlay visible={isPending || isLoading} />

        {dataset && (
          <DatasetForm
            initialValues={{
              name: dataset.name,
              description: dataset.description,
              datasetType: dataset.dataset_type,
              classes: dataset.labels.map((label) => ({
                name: label.name,
                color: label.color
              })),
              labelingType: dataset.labeling_type
            }}
            submitLabel={<FormattedMessage id="saveChanges" />}
            disableLabelingClasses={hasFiles}
            disableLabelingType={hasFiles}
            onCancel={onClose}
            onSubmit={(values) => void handleDatasetUpdate(values)}
          />
        )}
      </Box>
    </Modal>
  )
}
