export type SortConfig<T extends string = string> = {
  key?: T
  order?: 'asc' | 'desc'
}

export const buildSortParams = (sortConfig: SortConfig) => {
  return {
    ordering: sortConfig.key
      ? `${sortConfig.order === 'desc' ? '-' : ''}${sortConfig.key}`
      : undefined
  }
}
