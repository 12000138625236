import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router'
import { SortConfig } from '@/utils/sorting'

type TableState<T extends SortConfig> = {
  activePage: number
  sortConfig: T | Record<string, never>
}

const TABLE_URL_PARAMS = {
  activePage: 'page',
  sortKey: 'sortKey',
  sortOrder: 'sortOrder'
}

export const useTableState = <T extends SortConfig>(
  initialState: Partial<TableState<T>> = {}
) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const activePage =
    searchParams.get(TABLE_URL_PARAMS.activePage) ||
    String(initialState.activePage ?? '1')

  const sortKey =
    searchParams.get(TABLE_URL_PARAMS.sortKey) || initialState.sortConfig?.key

  const sortOrder =
    searchParams.get(TABLE_URL_PARAMS.sortOrder) ||
    initialState.sortConfig?.order

  const tableState: TableState<T> = useMemo(
    () => ({
      activePage: Number(activePage),

      sortConfig:
        sortKey && sortOrder ? ({ key: sortKey, order: sortOrder } as T) : {}
    }),
    [activePage, sortKey, sortOrder]
  )

  const setTableState = useCallback(
    (newState: Partial<TableState<T>>) => {
      const { activePage, sortConfig } = newState

      const newParams = new URLSearchParams(searchParams)

      if (activePage) {
        newParams.set(TABLE_URL_PARAMS.activePage, String(activePage))
      }

      if (sortConfig?.key) {
        newParams.set(TABLE_URL_PARAMS.sortKey, sortConfig.key)
      }

      if (sortConfig?.order) {
        newParams.set(TABLE_URL_PARAMS.sortOrder, sortConfig.order)
      }

      setSearchParams(newParams, { replace: true })
    },

    [searchParams, setSearchParams]
  )

  return [tableState, setTableState] as const
}
