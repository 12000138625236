import { Badge, Select, SelectProps, ThemeIcon } from '@mantine/core'
import {
  IconLayersSelected,
  IconLayersUnion,
  IconRectangle,
  IconTag
} from '@tabler/icons-react'
import { ReactNode, forwardRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { DatasetLabelingType } from '@/types/dataset'
import { SelectOptionWithCheckbox } from './SelectOptionWithCheckbox'

type ConfigFields = {
  name: string
  description: string
  disabled: boolean
  icon: ReactNode
}

const labelingTypeNames: Record<string, ConfigFields> = {
  [DatasetLabelingType.ObjectDetection]: {
    name: 'annotation.type.objectDetection',
    description: 'annotation.type.objectDetection.description',
    disabled: false,
    icon: (
      <ThemeIcon variant="light">
        <IconRectangle style={{ width: '80%', height: '80%' }} />
      </ThemeIcon>
    )
  },
  [DatasetLabelingType.InstanceSegmentation]: {
    name: 'annotation.type.instanceSegmentation',
    description: 'annotation.type.instanceSegmentation.description',
    disabled: true,
    icon: (
      <ThemeIcon variant="light">
        <IconLayersSelected style={{ width: '80%', height: '80%' }} />
      </ThemeIcon>
    )
  },
  [DatasetLabelingType.SemanticSegmentation]: {
    name: 'annotation.type.semanticSegmentation',
    description: 'annotation.type.semanticSegmentation.description',
    disabled: true,
    icon: (
      <ThemeIcon variant="light">
        <IconLayersUnion style={{ width: '80%', height: '80%' }} />
      </ThemeIcon>
    )
  },
  [DatasetLabelingType.Classification]: {
    name: 'annotation.type.classification',
    description: 'annotation.type.classification.description',
    disabled: true,
    icon: (
      <ThemeIcon variant="light">
        <IconTag style={{ width: '80%', height: '80%' }} />
      </ThemeIcon>
    )
  }
}

export const LabelingTypeSelect = forwardRef<HTMLInputElement, SelectProps>(
  (props, ref) => {
    const intl = useIntl()

    const labelingTypeOptions = Object.entries(labelingTypeNames).map(
      ([type, config]) => ({
        value: type,
        label: intl.formatMessage({
          id: config.name
        }),
        disabled: config.disabled
      })
    )

    return (
      <Select
        ref={ref}
        label={<FormattedMessage id="annotation.type" />}
        maxDropdownHeight={260}
        allowDeselect={false}
        data={labelingTypeOptions}
        renderOption={({ option, checked }) => {
          const { icon, name, description } = labelingTypeNames[option.value]

          return (
            <SelectOptionWithCheckbox
              icon={icon}
              name={<FormattedMessage id={name} />}
              description={<FormattedMessage id={description} />}
              isChecked={checked || false}
              badge={
                option.disabled && (
                  <Badge size="xs" color="brand-secondary">
                    <FormattedMessage id="general.badges.comingSoon" />
                  </Badge>
                )
              }
            />
          )
        }}
        {...props}
      />
    )
  }
)

LabelingTypeSelect.displayName = 'LabelingTypeSelect'
