import {
  Button,
  Group,
  Tooltip as MantineTooltip,
  Stack,
  TextInput,
  Textarea
} from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { ReactNode } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  LabelAutocomplete,
  LabelOption
} from '@/components/labels/LabelAutocomplete/LabelAutocomplete'
import { DatasetLabelingType, DatasetType } from '@/types/dataset'
import { DatasetTypeSelect } from './DatasetTypeSelect'
import { LabelingTypeSelect } from './LabelingTypeSelect'

const DESCRIPTION_MAX_LENGTH = 500

type TooltipProps = {
  label: ReactNode | string
  children: ReactNode
  disabled?: boolean
}

const Tooltip = ({ label, children, disabled }: TooltipProps) => {
  return (
    <MantineTooltip
      disabled={disabled}
      label={label}
      position="bottom-start"
      w={320}
      offset={{
        mainAxis: 10,
        crossAxis: 20
      }}
      multiline
    >
      {children}
    </MantineTooltip>
  )
}

export type FormValues = {
  name: string
  description: string
  datasetType: DatasetType
  classes: LabelOption[]
  labelingType: DatasetLabelingType
}

type InternalFormValues = Omit<FormValues, 'datasetType' | 'labelingType'> & {
  datasetType: string
  labelingType: string
}

type DatasetFormProps = {
  initialValues?: FormValues
  submitLabel: string | ReactNode
  disableLabelingClasses?: boolean
  disableLabelingType?: boolean
  onCancel: () => void
  onSubmit: (formValues: FormValues) => void
}

export const DatasetForm = ({
  initialValues,
  submitLabel,
  disableLabelingClasses,
  disableLabelingType,
  onCancel,
  onSubmit
}: DatasetFormProps) => {
  const intl = useIntl()

  const form = useForm<InternalFormValues>({
    initialValues: {
      name: initialValues?.name || '',
      description: initialValues?.description || '',
      datasetType: String(initialValues?.datasetType || DatasetType.Primary),
      classes: initialValues?.classes || [],
      labelingType: String(
        initialValues?.labelingType || DatasetLabelingType.ObjectDetection
      )
    },

    validate: {
      name: isNotEmpty(
        intl.formatMessage({
          id: 'datasets.name.required'
        })
      ),
      description: (value) => {
        if (value.length > DESCRIPTION_MAX_LENGTH) {
          return intl.formatMessage(
            {
              id: 'datasets.description.maxChars'
            },
            {
              maxChars: DESCRIPTION_MAX_LENGTH
            }
          )
        }
      },
      classes: (value) => {
        if (value.length === 0) {
          return intl.formatMessage({
            id: 'annotation.classes.required'
          })
        }
      },
      labelingType: isNotEmpty(
        intl.formatMessage({
          id: 'annotation.type.required'
        })
      )
    }
  })

  const handleSubmit = (values: InternalFormValues) => {
    onSubmit({
      ...values,
      // Mantine select component supports only string values, so we need to convert them back to numbers
      datasetType: Number(values.datasetType),
      labelingType: Number(values.labelingType)
    })
  }

  return (
    <form noValidate onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <Stack gap="xl">
        <TextInput
          label={<FormattedMessage id="datasets.name" />}
          autoFocus
          data-autofocus // required for auto focus inside the modal
          {...form.getInputProps('name')}
        />

        <Textarea
          label={<FormattedMessage id="datasets.description" />}
          minRows={4}
          maxRows={4}
          autosize
          {...form.getInputProps('description')}
        />

        <DatasetTypeSelect {...form.getInputProps('datasetType')} />

        <Tooltip
          disabled={!disableLabelingType}
          label={<FormattedMessage id="annotation.classes.readOnly" />}
        >
          <LabelingTypeSelect
            disabled={disableLabelingType}
            {...form.getInputProps('labelingType')}
          />
        </Tooltip>

        <Tooltip
          disabled={!disableLabelingClasses}
          label={<FormattedMessage id="annotation.classes.readOnly" />}
        >
          <LabelAutocomplete
            label={<FormattedMessage id="annotation.classes" />}
            values={form.values.classes}
            error={form.errors.classes}
            disabled={disableLabelingClasses}
            onChange={(value) => form.setFieldValue('classes', value)}
          />
        </Tooltip>
      </Stack>

      <Group justify="end" mt={220}>
        <Button miw={120} variant="default" onClick={onCancel}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button type="submit" miw={120}>
          {submitLabel}
        </Button>
      </Group>
    </form>
  )
}
