import { Outlet } from 'react-router'
import { ModelProvider } from '@/providers/ModelContext'
import { BaseLayout } from '../BaseLayout/BaseLayout'
import { ModelDetailsSidebar } from '../ModelDetailsLayout/ModelDetailsSidebar'

export const ModelDetailsLayout = () => {
  return (
    <ModelProvider>
      <BaseLayout navSection={<ModelDetailsSidebar />}>
        <Outlet />
      </BaseLayout>
    </ModelProvider>
  )
}
