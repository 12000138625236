import { Button, Group } from '@mantine/core'
import { IconArrowBackUp } from '@tabler/icons-react'
import { PropsWithChildren } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router'
import { PlatformAlert } from '@/components/ui-shared/PlatformAlert/PlatformAlert'
import { RouterPath } from '@/router/paths'
import { useSession } from '@/session/SessionContext'
import { Resource, ResourceAction } from '@/types/permissions'

/**
 * Component to conditionally render children based on user permissions.
 *
 * Props:
 *   - allowFor: Array of resource-action pairs defining required permissions.
 *   - children: Components to render if permissions are met.
 *
 * Example:
 *   <CanAccessRoute
 *     allowFor={[
 *       ['ResourceA', ['Action1']],
 *       ['ResourceB', ['Action2']]
 *     ]}
 *   >
 *     <SomePage />
 *   </CanAccessRoute>
 */

type CanAccessRouteProps = PropsWithChildren<{
  allowFor: [Resource, ResourceAction[]][]
}>

export const CanAccessRoute = ({ allowFor, children }: CanAccessRouteProps) => {
  const { permissions } = useSession()

  // At least one of the permissions must match to allow access
  const hasPermission = allowFor.some(([resource, actions]) =>
    actions.some((action) => permissions[resource]?.includes(action))
  )

  if (!hasPermission) {
    return (
      <PlatformAlert
        m="md"
        variant="warning"
        title={<FormattedMessage id="permissions.denied.title" />}
      >
        <Group>
          <FormattedMessage id="permissions.denied.description" />

          <Button
            size="xs"
            leftSection={<IconArrowBackUp size={16} />}
            component={Link}
            to={RouterPath.root}
          >
            <FormattedMessage id="returnHome" />
          </Button>
        </Group>
      </PlatformAlert>
    )
  }

  return <>{children}</>
}
