import { Outlet } from 'react-router'
import { ApplicationProvider } from '@/providers/ApplicationContext'
import { BaseLayout } from '../BaseLayout/BaseLayout'
import { AppDetailsSidebar } from './AppDetailsSidebar'

export const AppRouteProvider = () => {
  return (
    <ApplicationProvider>
      <Outlet />
    </ApplicationProvider>
  )
}

export const AppDetailsLayout = () => {
  return (
    <BaseLayout navSection={<AppDetailsSidebar />}>
      <Outlet />
    </BaseLayout>
  )
}
