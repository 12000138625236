import { Button, Group, Image, Stack, Text } from '@mantine/core'
import { IconArrowLeft } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router'
import VpLogoSrc from '@/assets/vp-stripes.png'
import { ModelStepperHandler } from '@/components/models/ModelDetails/ModelStepperHandler'
import { ModelStatus } from '@/components/models/ModelStatus/ModelStatus'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'

export const ModelDetailsSidebar = () => {
  const { application } = useApplicationContext()
  const { model } = useModelContext()

  if (!application || !model) {
    return null
  }

  return (
    <Stack gap="xl" p={4}>
      <Button
        variant="light"
        size="xs"
        leftSection={<IconArrowLeft size={16} />}
        component={Link}
        to={buildAppLink(application.id, ApplicationNestedPath.models)}
      >
        <FormattedMessage id="models.backToMyModels" />
      </Button>

      <Group wrap="nowrap" align="center" gap="sm" ml="xs">
        <Image src={model.root_model?.brand_logo || VpLogoSrc} w={32} h={32} />

        <Stack gap={4}>
          <Text size="sm" fw={600}>
            {model.name}
          </Text>

          {model.status !== null && <ModelStatus status={model.status} />}
        </Stack>
      </Group>

      <ModelStepperHandler appId={application.id} model={model} />
    </Stack>
  )
}
