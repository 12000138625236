import { Breadcrumbs, Card, Stack } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation, useParams } from 'react-router'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import {
  useGetDatasetDetails,
  useGetDatasetVersions
} from '@/queries/datasetQueries'
import { RouterPath } from '@/router/paths'
import { DatasetVersion } from '@/types/dataset'
import { CrudAction, Resource } from '@/types/permissions'
import { BreadcrumbLink } from '../ui-shared/Breadcrumbs/BreadcrumbLink'
import { BreadcrumbsSkeleton } from '../ui-shared/Breadcrumbs/BreadcrumbsSkeleton'
import { ErrorWithReload } from '../ui-shared/ErrorWithReload/ErrorWithReload'
import { DatasetToolbar } from './DatasetToolbar/DatasetToolbar'
import { DatasetToolbarSkeleton } from './DatasetToolbar/DatasetToolbarSkeleton'
import { DeleteDatasetVersionModal } from './DeleteDatasetVersionModal/DeleteDatasetVersionModal'
import { UpdateDatasetModal } from './UpdateDatasetModal/UpdateDatasetModal'
import { UploadableMediaListHandler } from './UploadableMediaList/UploadableMediaListHandler'

export const DatasetDetailsPage = () => {
  const { datasetId } = useParams()
  const location = useLocation()

  const [activeVersion, setActiveVersion] = useState<DatasetVersion | null>(
    null
  )

  const [
    isDeleteDatasetVersionModalOpened,
    {
      open: openDeleteDatasetVersionModal,
      close: closeDeleteDatasetVersionModal
    }
  ] = useDisclosure(false)

  const [
    isUpdateDatasetModalOpened,
    { open: openUpdateDatasetModal, close: closeUpdateDatasetModal }
  ] = useDisclosure(false)

  const { datasetListParams } = (location.state || {}) as {
    datasetListParams?: string
  }

  const {
    data: dataset,
    isLoading,
    refetch: refetchDatasetDetails,
    isError: isDatasetError
  } = useGetDatasetDetails(datasetId || '', !!datasetId)

  const {
    data: versionsData,
    refetch: refetchVersions,
    isFetching: isVersionsFetching,
    isFetchedAfterMount: isVersionsFetchedAfterMount
  } = useGetDatasetVersions(datasetId || '', !!datasetId)

  const versions = useMemo(() => {
    return versionsData?.pages?.flatMap((page) => page.results) || []
  }, [versionsData])

  const [canDeleteVersion, canEditLabels] = useHasPermissions(
    Resource.Datasets,
    [CrudAction.Delete, CrudAction.Update]
  )

  useEffect(() => {
    // Make sure to set the first version as active when the versions are fetched after the component is mounted
    // https://github.com/TanStack/query/discussions/6656
    if (versions.length && !activeVersion && isVersionsFetchedAfterMount) {
      setActiveVersion(versions[0])
    }
  }, [versions, activeVersion, isVersionsFetchedAfterMount])

  const handleVersionChange = (versionId: string) => {
    setActiveVersion(
      versions.find((version) => version.id === versionId) || null
    )
  }

  const handleDatasetVersionDeleted = async () => {
    closeDeleteDatasetVersionModal()
    await refetchAndSyncVersions()
  }

  const handleDatasetUpdated = () => {
    closeUpdateDatasetModal()
    void refetchDatasetDetails()
  }

  const refetchAndSyncVersions = async () => {
    const { data } = await refetchVersions()
    setActiveVersion(data?.pages.flatMap((page) => page.results)[0] || null)
  }

  if (isLoading) {
    return (
      <Stack gap="xl">
        <BreadcrumbsSkeleton />

        <Card withBorder>
          <DatasetToolbarSkeleton />
        </Card>
      </Stack>
    )
  }

  if (isDatasetError) {
    return (
      <ErrorWithReload onReload={() => void refetchDatasetDetails()}>
        <FormattedMessage id="datasets.data.error" />
      </ErrorWithReload>
    )
  }

  if (dataset) {
    return (
      <>
        <Breadcrumbs mb="lg">
          <BreadcrumbLink
            label={<FormattedMessage id="navLinks.datasets" />}
            to={{
              pathname: RouterPath.datasets,
              search: datasetListParams
            }}
          />

          <span>{dataset.name}</span>
        </Breadcrumbs>

        <Card px="lg" mb="sm" withBorder>
          <DatasetToolbar
            datasetName={dataset.name}
            labels={dataset.labels}
            versions={versions}
            activeVersionId={activeVersion?.id}
            isVersionPending={isVersionsFetching}
            showDeleteVersionButton={canDeleteVersion}
            showEditLabelsButton={canEditLabels}
            onVersionChange={handleVersionChange}
            onVersionDelete={openDeleteDatasetVersionModal}
            onEditLabels={openUpdateDatasetModal}
          />
        </Card>

        {activeVersion && (
          <UploadableMediaListHandler
            key={activeVersion.id}
            version={activeVersion}
            onVersionCreated={() => void refetchAndSyncVersions()}
          />
        )}

        <DeleteDatasetVersionModal
          opened={isDeleteDatasetVersionModalOpened}
          datasetVersionId={activeVersion?.id}
          onClose={closeDeleteDatasetVersionModal}
          onVersionDeleted={() => void handleDatasetVersionDeleted()}
        />

        <UpdateDatasetModal
          opened={isUpdateDatasetModalOpened}
          datasetId={dataset.id}
          onClose={closeUpdateDatasetModal}
          onDatasetUpdated={handleDatasetUpdated}
        />
      </>
    )
  }

  return null
}
