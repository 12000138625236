import { Box, Flex } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import {
  useGetDatasetDetails,
  useGetDatasetVersions
} from '@/queries/datasetQueries'
import { useUpdateModel } from '@/queries/modelQueries'
import { showToast } from '@/theme/notifications'
import { DatasetVersion, DatasetVersionStatus } from '@/types/dataset'
import { CrudAction, Resource } from '@/types/permissions'
import { PageWithFixedToolbar } from '../layout/PageWithFixedToolbar/PageWithFixedToolbar'
import { CreateTrainingHandler } from '../trainings/CreateTrainingHandler'
import { TrainingDrawer } from '../trainings/TrainingDrawer/TrainingDrawer'
import { ConfirmDatasetCompletion } from './ConfirmDatasetCompletion/ConfirmDatasetCompletion'
import { DatasetToolbar } from './DatasetToolbar/DatasetToolbar'
import { DatasetToolbarSkeleton } from './DatasetToolbar/DatasetToolbarSkeleton'
import { DeleteDatasetVersionModal } from './DeleteDatasetVersionModal/DeleteDatasetVersionModal'
import { UpdateDatasetModal } from './UpdateDatasetModal/UpdateDatasetModal'
import { UploadableMediaListHandler } from './UploadableMediaList/UploadableMediaListHandler'

export const LabelDataPage = () => {
  const intl = useIntl()

  const [completionConfirmedVersionId, setCompletionConfirmedVersionId] =
    useState('')

  const [
    isTrainingDrawerOpened,
    { open: openTrainingDrawer, close: closeTrainingDrawer }
  ] = useDisclosure(false)

  const [
    isConfirmCompletionModalOpened,
    { open: openConfirmCompletionModal, close: closeConfirmCompletionModal }
  ] = useDisclosure(false)

  const [
    isDeleteDatasetVersionModalOpened,
    {
      open: openDeleteDatasetVersionModal,
      close: closeDeleteDatasetVersionModal
    }
  ] = useDisclosure(false)

  const [
    isUpdateDatasetModalOpened,
    { open: openUpdateDatasetModal, close: closeUpdateDatasetModal }
  ] = useDisclosure(false)

  const [canDeleteVersion, canEditLabels] = useHasPermissions(
    Resource.Datasets,
    [CrudAction.Delete, CrudAction.Update]
  )

  const { application } = useApplicationContext()
  const { model } = useModelContext()

  const datasetId = model?.dataset_version?.dataset || ''
  const datasetVersionId = model?.dataset_version?.id || ''

  const { mutateAsync: updateModel, isPending: isModelUpdatePending } =
    useUpdateModel(model?.id || '')

  const {
    data: dataset,
    isLoading,
    refetch: refetchDatasetDetails
  } = useGetDatasetDetails(datasetId, datasetId !== '')

  const { data: versionsData, refetch: refetchVersions } =
    useGetDatasetVersions(datasetId, datasetId !== '')

  const versions = versionsData?.pages?.flatMap((page) => page.results) || []

  const activeVersion =
    versions.find((version) => version.id === datasetVersionId) ||
    (versions[0] as DatasetVersion | undefined)

  const updateModelDatasetVersion = async (newVersionId: string) => {
    try {
      await updateModel({
        modelId: model?.id || '',
        data: {
          dataset_version_id: newVersionId
        }
      })
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'datasets.assign.error' })

      showToast(message, 'error')
    }
  }

  const handleVersionChange = (value: string) => {
    void updateModelDatasetVersion(value)
  }

  const handleModelTrainClick = () => {
    if (
      activeVersion?.status !== DatasetVersionStatus.Completed &&
      completionConfirmedVersionId !== activeVersion?.id
    ) {
      openConfirmCompletionModal()
      return
    }

    openTrainingDrawer()
  }

  const handleConfirmCompletion = () => {
    closeConfirmCompletionModal()
    setCompletionConfirmedVersionId(activeVersion?.id || '')
    openTrainingDrawer()
  }

  const handleVersionCreated = (newVersion: DatasetVersion) => {
    void updateModelDatasetVersion(newVersion.id)
    void refetchVersions()
  }

  const handleDatasetVersionDeleted = async () => {
    closeDeleteDatasetVersionModal()

    const { data } = await refetchVersions()

    const lastVersion = data?.pages.flatMap((page) => page.results)[0]

    if (lastVersion) {
      void updateModelDatasetVersion(lastVersion.id)
    }
  }

  const handleDatasetUpdated = () => {
    closeUpdateDatasetModal()
    void refetchDatasetDetails()
  }

  return (
    <PageWithFixedToolbar
      toolbar={
        <>
          {isLoading && <DatasetToolbarSkeleton />}

          {dataset && (
            <Flex direction="column" justify="center" mih={60}>
              <DatasetToolbar
                datasetName={dataset.name}
                labels={dataset.labels}
                versions={versions}
                activeVersionId={activeVersion?.id}
                isVersionPending={isModelUpdatePending}
                showDeleteVersionButton={canDeleteVersion}
                showEditLabelsButton={canEditLabels}
                onVersionChange={handleVersionChange}
                onVersionDelete={openDeleteDatasetVersionModal}
                onEditLabels={openUpdateDatasetModal}
              />
            </Flex>
          )}
        </>
      }
    >
      {activeVersion && (
        <Box mb="lg">
          <UploadableMediaListHandler
            key={activeVersion.id}
            version={activeVersion}
            showTrainModelButton
            onTrainModel={handleModelTrainClick}
            onVersionCreated={handleVersionCreated}
          />
        </Box>
      )}

      <TrainingDrawer
        opened={isTrainingDrawerOpened}
        onClose={closeTrainingDrawer}
      >
        {application && model && dataset && (
          <CreateTrainingHandler
            applicationId={application.id}
            model={model}
            onCancel={closeTrainingDrawer}
          />
        )}
      </TrainingDrawer>

      <ConfirmDatasetCompletion
        opened={isConfirmCompletionModalOpened}
        onClose={closeConfirmCompletionModal}
        onConfirm={handleConfirmCompletion}
      />

      <DeleteDatasetVersionModal
        opened={isDeleteDatasetVersionModalOpened}
        datasetVersionId={activeVersion?.id}
        onClose={closeDeleteDatasetVersionModal}
        onVersionDeleted={() => void handleDatasetVersionDeleted()}
      />

      <UpdateDatasetModal
        opened={isUpdateDatasetModalOpened}
        datasetId={datasetId}
        onClose={closeUpdateDatasetModal}
        onDatasetUpdated={handleDatasetUpdated}
      />
    </PageWithFixedToolbar>
  )
}
