import { Modal } from '@mantine/core'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Dataset, DatasetType } from '@/types/dataset'
import { CreateDatasetHandler } from '../CreateDatasetHandler/CreateDatasetHandler'
import { ImageCropHandler } from '../ImageCrop/ImageCropHandler'

type CreateDatasetModalProps = {
  opened: boolean
  onClose: () => void
  onDatasetCreated: (dataset: Dataset) => void
  onCropSuccess: () => void
}

export const CreateDatasetModal = ({
  opened,
  onClose,
  onDatasetCreated,
  onCropSuccess
}: CreateDatasetModalProps) => {
  const [targetCropDataset, setTargetCropDataset] = useState('')

  const resetAndClose = () => {
    setTargetCropDataset('')
    onClose()
  }

  const handleDatasetCreated = (dataset: Dataset) => {
    onDatasetCreated(dataset)

    if (dataset.dataset_type === DatasetType.Secondary) {
      setTargetCropDataset(dataset.id)
    } else {
      resetAndClose()
    }
  }

  const handleCropSuccess = () => {
    resetAndClose()
    onCropSuccess()
  }

  return (
    <Modal
      size="lg"
      opened={opened}
      title={<FormattedMessage id="datasets.create" />}
      onClose={resetAndClose}
    >
      {!targetCropDataset && (
        <CreateDatasetHandler
          onCancel={resetAndClose}
          onDatasetCreated={handleDatasetCreated}
        />
      )}

      {targetCropDataset && (
        <ImageCropHandler
          targetDatasetId={targetCropDataset}
          onSkip={resetAndClose}
          onCropSuccess={handleCropSuccess}
        />
      )}
    </Modal>
  )
}
