import { Drawer, Title } from '@mantine/core'
import { useInterval } from '@mantine/hooks'
import { MRT_ColumnFiltersState, MRT_SortingState } from 'mantine-react-table'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useGetDevices } from '@/queries/deviceQueries'
import {
  useGetEventListFilterItems,
  useGetEvents
} from '@/queries/eventQueries'
import { CrudAction, EventAction, Resource } from '@/types/permissions'
import { EventDetail } from './EventDetail/EventDetail'
import { EventsFilterOptions, EventsTable } from './EventsList/EventsTable'
import { useEventIdParam } from './EventsList/useEventIdParam'
import { buildSortingAndFilterParams } from './helpers/buildSortingAndFilterParams'

const POLL_INTERVAL = 10000

export const EventsPage = () => {
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
  const [eventDetailsId, setEventDetailsId] = useEventIdParam()

  const { application } = useApplicationContext()

  const [canDeleteEvents] = useHasPermissions(Resource.Events, [
    CrudAction.Delete
  ])

  const [canDownloadEvents, canAddNotes] = useHasPermissions(Resource.Events, [
    EventAction.Download,
    EventAction.AddNote
  ])

  const sortingAndFilterParams = buildSortingAndFilterParams({
    sorting,
    columnFilters
  }) as Record<string, string>

  const {
    data: eventsData,
    fetchNextPage,
    isFetching,
    refetch
  } = useGetEvents(application?.id || '', sortingAndFilterParams)

  const { start, stop, active } = useInterval(() => {
    if (!isFetching) {
      void refetch()
    }
  }, POLL_INTERVAL)

  useEffect(() => {
    if (eventsData && !active) {
      start()
    }
  }, [eventsData, active, start])

  // Cleanup function to stop the interval when the component is unmounted
  useEffect(() => {
    return stop
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { data: devicesData } = useGetDevices()
  const { data: eventListFilterData } = useGetEventListFilterItems(
    application?.id || ''
  )

  const devices = devicesData?.pages.flatMap((page) => page.results) || []

  const events = eventsData?.pages?.flatMap((page) => page?.results) || []

  const filterOptions: EventsFilterOptions = {
    devices: devices.map(({ id, label }) => ({ value: id, label })),
    detections: (eventListFilterData?.detections ?? []).map((detection) => ({
      value: detection,
      label: detection.split('->').pop() ?? detection
    })),
    roiNames: [
      ...(eventListFilterData?.roi_names ?? []),
      ...(eventListFilterData?.line_names ?? [])
    ].map((name) => ({ value: name, label: name }))
  }

  return (
    <>
      <Title order={2} mb="md">
        <FormattedMessage id="events.title" />
      </Title>

      <EventsTable
        events={events}
        totalEvents={eventsData?.pages[0]?.count || 0}
        applicationId={application?.id || ''}
        isFetching={isFetching}
        sorting={sorting}
        columnFilters={columnFilters}
        filterOptions={filterOptions}
        showDeleteButton={canDeleteEvents}
        showDownloadButton={canDownloadEvents}
        showEventNote={canAddNotes}
        fetchNextPage={() => void fetchNextPage()}
        refetch={() => void refetch()}
        onSortingChange={setSorting}
        onFilterChange={setColumnFilters}
        onRowClick={(eventId) => setEventDetailsId(eventId)}
      />

      <Drawer
        position="right"
        size="80%"
        opened={eventDetailsId !== null}
        withCloseButton={false}
        transitionProps={{
          transition: 'fade-left',
          duration: 100
        }}
        onClose={() => setEventDetailsId(null)}
      >
        {eventDetailsId && (
          <EventDetail
            appId={application?.id || ''}
            eventId={eventDetailsId}
            onBackClick={() => setEventDetailsId(null)}
          />
        )}
      </Drawer>
    </>
  )
}
