import {
  ComboboxItem,
  Group,
  Select,
  SelectProps,
  Text,
  ThemeIcon
} from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { useIntl } from 'react-intl'
import { Dataset, DatasetType } from '@/types/dataset'
import { DatasetTypeChip } from '../DatasetTypeChip/DatasetTypeChip'

type SelectOptionProps = {
  name: string
  datasetType: DatasetType
  isChecked: boolean
}

const SelectOption = ({ name, datasetType, isChecked }: SelectOptionProps) => {
  return (
    <Group flex="1" align="start" wrap="nowrap" py={4}>
      <Group wrap="nowrap" gap="xs" align="center">
        <Text size="xs">{name}</Text>
        <DatasetTypeChip datasetType={datasetType} />
      </Group>

      {isChecked && (
        <ThemeIcon
          color="green"
          size="xs"
          style={{ marginInlineStart: 'auto' }}
        >
          <IconCheck style={{ width: '80%', height: '80%' }} />
        </ThemeIcon>
      )}
    </Group>
  )
}

type DatasetSelectProps = SelectProps & {
  datasets: Dataset[]
}

export const DatasetSelect = (props: DatasetSelectProps) => {
  const { datasets, ...selectProps } = props
  const intl = useIntl()

  const datasetOptions = datasets.map((dataset) => ({
    value: dataset.id,
    label: dataset.name,
    dataset: dataset
  }))

  return (
    <Select
      placeholder={intl.formatMessage({
        id: 'datasets.crop.datasetSelect.placeholder'
      })}
      data={datasetOptions}
      allowDeselect={false}
      renderOption={({ option, checked }) => {
        const { dataset } = option as ComboboxItem & { dataset: Dataset }

        return (
          <SelectOption
            name={option.label}
            datasetType={dataset.dataset_type}
            isChecked={checked || false}
          />
        )
      }}
      {...selectProps}
    />
  )
}
