import { Group, Stack, Text, ThemeIcon } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { ReactNode } from 'react'

type SelectOptionWithCheckboxProps = {
  icon: ReactNode
  name: string | ReactNode
  description: string | ReactNode
  badge?: ReactNode
  isChecked: boolean
}

export const SelectOptionWithCheckbox = ({
  icon,
  name,
  description,
  badge,
  isChecked
}: SelectOptionWithCheckboxProps) => {
  return (
    <Group flex="1" align="start" wrap="nowrap" py={4}>
      {icon}

      <Stack gap={4}>
        <Group wrap="nowrap" gap="xs" align="center">
          <Text size="sm" fw={600}>
            {name}
          </Text>

          {badge}
        </Group>

        <Text size="xs">{description}</Text>
      </Stack>

      {isChecked && (
        <ThemeIcon
          color="green"
          size="xs"
          style={{ marginInlineStart: 'auto' }}
        >
          <IconCheck style={{ width: '80%', height: '80%' }} />
        </ThemeIcon>
      )}
    </Group>
  )
}
