import { SimpleGrid } from '@mantine/core'
import { InfiniteScroll } from '@/components/ui-shared/InfiniteScroll/InfiniteScroll'
import { useGetMLModels } from '@/queries/modelQueries'
import { BaseModelCard } from '../../BaseModelSelection/BaseModelCard/BaseModelCard'
import { ModelsErrorLoader } from '../ModelsErrorLoader/ModelsErrorLoader'

type MasterModelListProps = {
  selectedModelId: string
  onSelectionChange: (modelId: string) => void
}
export const PreTrainedModelList = ({
  selectedModelId,
  onSelectionChange
}: MasterModelListProps) => {
  const {
    data: libraryModels,
    isFetching: isLibraryFetching,
    isError: isErrorLibraryModels,
    refetch: refetchLibraryModels,
    fetchNextPage: fetchNextPageLibraryModels,
    hasNextPage: hasNextPageLibraryModels
  } = useGetMLModels({
    is_library: true,
    is_master_model: false
  })

  const refetchPreTrainedModels = () => {
    if (isErrorLibraryModels) void refetchLibraryModels()
  }

  const handleLoadMorePretrainedModels = () => {
    void fetchNextPageLibraryModels()
  }

  const libraryMlModels =
    libraryModels?.pages?.flatMap((page) => page.results) || []

  if (isErrorLibraryModels) {
    return <ModelsErrorLoader onReload={() => void refetchPreTrainedModels()} />
  }

  return (
    <InfiniteScroll
      hasMore={hasNextPageLibraryModels}
      isFetching={isLibraryFetching}
      onLoadMore={handleLoadMorePretrainedModels}
    >
      <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 6 }} spacing="xl">
        {libraryMlModels.map((model) => (
          <BaseModelCard
            key={model.id}
            modelName={model.name}
            labels={model.dataset_version?.labels || []}
            brandLogo={model.brand_logo || undefined}
            brandName={model.brand_name_display || undefined}
            networkType={model.network_type || undefined}
            trainable={model.can_be_trained}
            testable={model.can_be_tested}
            deployable={model.can_be_deployed}
            isSelected={selectedModelId === model.id}
            showBadges={
              model.can_be_deployed ||
              model.can_be_trained ||
              model.can_be_tested
            }
            isBeta={model.is_beta}
            showInfoOnHover
            onClick={() => onSelectionChange(model.id)}
          />
        ))}
      </SimpleGrid>
    </InfiniteScroll>
  )
}
