import {
  Combobox,
  Pill,
  PillsInput,
  Stack,
  Text,
  rem,
  useCombobox,
  useMantineTheme
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { ReactNode, forwardRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { InfiniteScroll } from '@/components/ui-shared/InfiniteScroll/InfiniteScroll'
import { useGetLabels } from '@/queries/labelQueries'
import { getRandomColor } from '@/theme/randomColor'
import { removeSpecialChars } from './removeSpecialChars'

export type LabelOption = {
  name: string
  color: string
}

type TextTransform = 'uppercase' | 'lowercase' | 'capitalize' | 'none'

const getPillStyles = (color: string) => ({
  root: {
    color: 'white',
    backgroundColor: color
  },
  label: {
    textTransform: 'uppercase' as TextTransform, // fix mantine type error
    fontWeight: 600,
    fontSize: rem(11)
  }
})

type LabelAutocompleteProps = {
  values: LabelOption[]
  label?: string | ReactNode
  placeholder?: string
  disabled?: boolean
  error?: ReactNode
  onChange: (values: LabelOption[]) => void
}

export const LabelAutocomplete = forwardRef<
  HTMLInputElement,
  LabelAutocompleteProps
>(({ values, label, placeholder, disabled = false, error, onChange }, ref) => {
  const theme = useMantineTheme()

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  })

  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebouncedValue(search, 200)

  const { data, isFetching, fetchNextPage, hasNextPage } = useGetLabels({
    urlParams: { search: removeSpecialChars(debouncedSearch) },
    enabled: debouncedSearch.trim().length > 1 || combobox.dropdownOpened
  })

  const labels = data?.pages.flatMap((page) => page.results) || []

  const labelOptions = labels.map((label) => ({
    id: label.id,
    name: label.name,
    color: label.color
  }))

  const filteredOptions = labelOptions.filter(
    (item) =>
      !values.find((v) => v.name.toLowerCase() === item.name.toLowerCase())
  )

  const hasExactMatch = filteredOptions.some(
    (item) => item.name.toLowerCase() === search.trim().toLowerCase()
  )

  const closeDropdown = () => {
    combobox.closeDropdown()
    combobox.targetRef.current?.blur()
  }

  const handleValueSelect = (val: string) => {
    if (val === '$create') {
      const searchValue = removeSpecialChars(search.trim())

      const hasMatch = values.find(
        (item) => item.name.toLowerCase() === searchValue.toLowerCase()
      )

      if (!hasMatch) {
        onChange([...values, { name: searchValue, color: getRandomColor() }])
      }
    } else {
      const selectedOption = labelOptions.find((label) => label.name === val)

      if (selectedOption) {
        onChange([...values, selectedOption])
      }
    }

    setSearch('')
    closeDropdown()
  }

  const handleValueRemove = (val: LabelOption) => {
    if (disabled) {
      return
    }

    onChange(values.filter((v) => v.name !== val.name))
    combobox.focusTarget()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && search.length === 0) {
      event.preventDefault()
      handleValueRemove(values[values.length - 1])
    }
  }

  return (
    <Combobox
      store={combobox}
      shadow="sm"
      disabled={disabled}
      styles={{
        empty: {
          fontSize: rem(13),
          color: theme.colors.dark[9]
        }
      }}
      offset={0}
      onOptionSubmit={handleValueSelect}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          ref={ref}
          disabled={disabled}
          label={label}
          error={error}
          onClick={() => combobox.openDropdown()}
        >
          <Pill.Group disabled={disabled}>
            {values.map((item) => (
              <Pill
                key={item.name}
                styles={getPillStyles(item.color)}
                withRemoveButton={!disabled}
                onRemove={() => handleValueRemove(item)}
              >
                {item.name}
              </Pill>
            ))}

            <Combobox.EventsTarget>
              <PillsInput.Field
                value={search}
                placeholder={placeholder}
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                onChange={(event) => {
                  setSearch(event.currentTarget.value)
                }}
                onKeyDown={handleKeyDown}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options mah={220} style={{ overflowY: 'auto' }}>
          <InfiniteScroll
            hasMore={hasNextPage}
            isFetching={isFetching}
            onLoadMore={() => void fetchNextPage()}
          >
            {filteredOptions.map((label) => (
              <Combobox.Option key={label.id} value={label.name}>
                <Pill styles={getPillStyles(label.color)}>{label.name}</Pill>
              </Combobox.Option>
            ))}
          </InfiniteScroll>

          {search.trim().length > 1 && !hasExactMatch && !isFetching && (
            <Combobox.Option value="$create">
              <Stack gap="xs">
                <Text size="sm" c="blue" fw={600}>
                  <FormattedMessage
                    id="annotation.classes.input.add"
                    values={{
                      name: `"${search}"`
                    }}
                  />
                </Text>

                <Text
                  size="xs"
                  c="dimmed"
                  styles={{
                    root: {
                      textTransform: 'lowercase'
                    }
                  }}
                >
                  (
                  <FormattedMessage id="annotation.classes.input.validChars" />)
                </Text>
              </Stack>
            </Combobox.Option>
          )}

          {filteredOptions.length === 0 &&
            search.trim().length === 0 &&
            !isFetching && (
              <Combobox.Empty ta="left">
                <FormattedMessage id="annotation.classes.input.startTyping" />
              </Combobox.Empty>
            )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
})

LabelAutocomplete.displayName = 'LabelAutocomplete'
