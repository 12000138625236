import {
  Box,
  Button,
  Center,
  Flex,
  Group,
  Loader,
  LoadingOverlay,
  Pagination,
  Title
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconPlus } from '@tabler/icons-react'
import { isEqual } from 'lodash-es'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHasPermissions } from '@/permissions/useHasPermissions'
import { useGetDatasetList } from '@/queries/datasetQueries'
import { CrudAction, Resource } from '@/types/permissions'
import { getPageCount, getPageOffset } from '@/utils/pagination'
import { buildSortParams } from '@/utils/sorting'
import { ErrorWithReload } from '../ui-shared/ErrorWithReload/ErrorWithReload'
import { useTableState } from '../ui-shared/Tables/useTableState'
import { CreateDatasetModal } from './CreateDatasetModal/CreateDatasetModal'
import { DatasetList, TableSortConfig } from './DatasetList/DatasetList'
import { EmptyDatasetList } from './DatasetList/EmptyDatasetList'
import { DatasetPreview } from './DatasetPreview/DatasetPreview'
import { DeleteDatasetModal } from './DeleteDatasetModal/DeleteDatasetModal'
import { UpdateDatasetModal } from './UpdateDatasetModal/UpdateDatasetModal'

const PAGE_SIZE = 10

const DEFAULT_SORT_CONFIG: TableSortConfig = {
  key: 'created_at',
  order: 'desc'
}

export const DatasetListPage = () => {
  const [previewDataset, setPreviewDataset] = useState<string>('')
  const [datasetToEdit, setDatasetToEdit] = useState<string>('')
  const [datasetToRemove, setDatasetToRemove] = useState<string>('')

  const [
    isCreateModalOpened,
    { open: openCreateModal, close: closeCreateModal }
  ] = useDisclosure(false)

  const [canCreateDataset] = useHasPermissions(Resource.Datasets, [
    CrudAction.Create
  ])

  const [tableState, setTableState] = useTableState({
    activePage: 1,
    sortConfig: DEFAULT_SORT_CONFIG
  })

  const { activePage, sortConfig } = tableState

  const { data, isFetching, isLoading, isError, refetch } = useGetDatasetList({
    is_library: false,
    offset: getPageOffset(activePage, PAGE_SIZE),
    limit: PAGE_SIZE,
    ordering: buildSortParams(sortConfig).ordering
  })

  const datasets = data?.results || []

  const totalPageCount = getPageCount(data?.count || 0, PAGE_SIZE)

  useEffect(() => {
    if (totalPageCount > 0 && activePage > totalPageCount) {
      setTableState({ activePage: totalPageCount })
    }
  }, [activePage, totalPageCount, setTableState])

  const handleOnReload = () => {
    void refetch()
  }

  const handleDatasetCreated = () => {
    if (activePage === 1 && isEqual(sortConfig, DEFAULT_SORT_CONFIG)) {
      void refetch()
    } else {
      setTableState({
        activePage: 1,
        sortConfig: DEFAULT_SORT_CONFIG
      })
    }
  }

  const handleDatasetUpdated = () => {
    void refetch()
    setDatasetToEdit('')
  }

  const handleDatasetDeleted = () => {
    void refetch()
    setDatasetToRemove('')
  }

  return (
    <>
      <Group wrap="nowrap" mb="lg" gap="xl">
        <Title order={2}>
          <FormattedMessage id="datasets.list.title" />
        </Title>

        {datasets.length > 0 && canCreateDataset && (
          <Button
            leftSection={<IconPlus size={16} />}
            size="xs"
            onClick={openCreateModal}
          >
            <FormattedMessage id="datasets.list.buttons.create" />
          </Button>
        )}
      </Group>

      {isLoading && (
        <Center h={120}>
          <Loader size="md" />
        </Center>
      )}

      {isError && !isLoading && datasets.length === 0 && (
        <ErrorWithReload onReload={handleOnReload}>
          <FormattedMessage id="datasets.list.error" />
        </ErrorWithReload>
      )}

      {datasets.length === 0 && !isLoading && !isError && (
        <EmptyDatasetList
          isCreateDisabled={!canCreateDataset}
          onDatasetCreate={openCreateModal}
        />
      )}

      {datasets.length > 0 && (
        <Box pos="relative">
          <LoadingOverlay
            visible={isFetching}
            loaderProps={{ children: ' ' }} // disable loader spinner / text
          />

          <DatasetList
            datasets={datasets}
            sortConfig={sortConfig}
            onSort={(sortConfig) =>
              setTableState({ activePage: 1, sortConfig })
            }
            onPreview={setPreviewDataset}
            onEdit={setDatasetToEdit}
            onDelete={setDatasetToRemove}
          />

          <Flex justify="end" mt="lg">
            <Pagination
              size="sm"
              total={totalPageCount}
              value={activePage}
              onChange={(value) => setTableState({ activePage: value })}
            />
          </Flex>
        </Box>
      )}

      <CreateDatasetModal
        opened={isCreateModalOpened}
        onClose={closeCreateModal}
        onDatasetCreated={handleDatasetCreated}
        onCropSuccess={() => void refetch()}
      />

      <DatasetPreview
        opened={previewDataset !== ''}
        datasetId={previewDataset}
        onClose={() => setPreviewDataset('')}
      />

      <UpdateDatasetModal
        opened={datasetToEdit !== ''}
        datasetId={datasetToEdit}
        onClose={() => setDatasetToEdit('')}
        onDatasetUpdated={handleDatasetUpdated}
      />

      <DeleteDatasetModal
        opened={datasetToRemove !== ''}
        datasetId={datasetToRemove}
        onDelete={handleDatasetDeleted}
        onClose={() => setDatasetToRemove('')}
      />
    </>
  )
}
