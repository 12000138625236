import { Button, Group, Stack, Text, ThemeIcon } from '@mantine/core'
import { IconArrowLeft, IconBox } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { RouterPath } from '@/router/paths'
import { AppNavLinks } from './AppNavLinks'

export const AppDetailsSidebar = () => {
  const { application } = useApplicationContext()

  if (!application) {
    return null
  }

  return (
    <Stack gap="xl" p={4}>
      <Button
        variant="light"
        size="xs"
        leftSection={<IconArrowLeft size={16} />}
        component={Link}
        to={RouterPath.apps}
      >
        <FormattedMessage id="apps.backToApps" />
      </Button>

      <Group wrap="nowrap" align="center" gap="xs">
        <ThemeIcon color="brand-secondary" variant="transparent" size="sm">
          <IconBox style={{ width: '100%', height: '100%' }} />
        </ThemeIcon>

        <Text size="sm" fw={600}>
          {application.name}
        </Text>
      </Group>

      <AppNavLinks appId={application.id} />
    </Stack>
  )
}
