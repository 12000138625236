import { useDisclosure } from '@mantine/hooks'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import {
  useGetDatasetVersionsLazy,
  useInvalidateDatasetList
} from '@/queries/datasetQueries'
import { useUpdateModel } from '@/queries/modelQueries'
import { showToast } from '@/theme/notifications'
import { Dataset, DatasetType } from '@/types/dataset'
import { MLModelType } from '@/types/model'
import { PageWithFixedToolbar } from '../layout/PageWithFixedToolbar/PageWithFixedToolbar'
import { useModelStepNavigation } from '../models/ModelDetails/useModelStepNavigation'
import { PlatformAlert } from '../ui-shared/PlatformAlert/PlatformAlert'
import { CreateDatasetModal } from './CreateDatasetModal/CreateDatasetModal'
import { DatasetListToolbar } from './DatasetListToolbar/DatasetListToolbar'
import { DatasetPreview } from './DatasetPreview/DatasetPreview'
import { SelectableDatasetList } from './SelectableDatasetList/SelectableDatasetList'

export const SelectDatasetPage = () => {
  const intl = useIntl()

  const [selectedDataset, setSelectedDataset] = useState<Dataset | null>(null)
  const [previewDataset, setPreviewDataset] = useState<Dataset | null>(null)

  const [isPending, setIsPending] = useState(false)

  const [
    isCreateModalOpened,
    { open: openCreateModal, close: closeCreateModal }
  ] = useDisclosure(false)

  const { application } = useApplicationContext()
  const { model } = useModelContext()

  const { goToLabelDataScreen } = useModelStepNavigation({
    appId: application?.id || '',
    modelId: model?.id || ''
  })

  const { getDatasetVersionsLazy } = useGetDatasetVersionsLazy()

  const { mutateAsync: updateModel } = useUpdateModel(model?.id || '')

  const { invalidateDatasetList } = useInvalidateDatasetList()

  const initialDatasetId = model?.dataset_version?.dataset || ''

  const assignDatasetAndNavigate = async (datasetId: string) => {
    setIsPending(true)

    try {
      const data = await getDatasetVersionsLazy(datasetId)
      const latestVersion = data?.pages[0]?.results[0].id

      await updateModel({
        modelId: model?.id || '',
        data: {
          dataset_version_id: latestVersion
        }
      })

      goToLabelDataScreen()
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'datasets.assign.error' })

      showToast(message, 'error')
    }

    setIsPending(false)
  }

  const handleOnContinue = () => {
    void assignDatasetAndNavigate(selectedDataset?.id || '')
  }

  return (
    <PageWithFixedToolbar
      toolbar={
        <DatasetListToolbar
          isDatasetSelected={!!selectedDataset}
          name={selectedDataset?.name || ''}
          isPending={isPending}
          onContinue={handleOnContinue}
        />
      }
    >
      <PlatformAlert
        mb="xl"
        title={<FormattedMessage id="datasets.select.banner.title" />}
        variant="info"
      >
        <FormattedMessage id="datasets.select.banner.description" />
      </PlatformAlert>

      <SelectableDatasetList
        initialDatasetId={initialDatasetId}
        selectedDatasetId={selectedDataset?.id || ''}
        selectableDatasetType={
          model?.model_type === MLModelType.Primary
            ? DatasetType.Primary
            : DatasetType.Secondary
        }
        onPreviewDataset={setPreviewDataset}
        onSelectDataset={setSelectedDataset}
        onCreateDataset={openCreateModal}
        onResetSelection={() => setSelectedDataset(null)}
      />

      <CreateDatasetModal
        opened={isCreateModalOpened}
        onClose={closeCreateModal}
        onDatasetCreated={invalidateDatasetList}
        onCropSuccess={invalidateDatasetList}
      />

      <DatasetPreview
        datasetId={previewDataset?.id}
        opened={!!previewDataset}
        onClose={() => setPreviewDataset(null)}
      />
    </PageWithFixedToolbar>
  )
}
