import { Group, Skeleton, Text } from '@mantine/core'

export const BreadcrumbsSkeleton = () => {
  return (
    <Group align="center">
      <Skeleton width={120} height={18} />
      <Text fw={700} size="xs">
        {' / '}
      </Text>
      <Skeleton width={120} height={18} />
    </Group>
  )
}
