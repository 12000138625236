import { Box } from '@mantine/core'
import { Outlet } from 'react-router'
import { BaseLayout } from '../BaseLayout/BaseLayout'
import { HomeNavLinks } from './HomeNavLinks'

export const HomeLayout = () => {
  return (
    <BaseLayout
      navSection={
        <Box pt={20}>
          <HomeNavLinks />
        </Box>
      }
    >
      <Outlet />
    </BaseLayout>
  )
}
