import { Image, Overlay, Stack, Text, UnstyledButton } from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { IconSearch } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import ImagePlaceholder from '@/assets/datasets/dataset.png'

const IMAGE_WIDTH = 80
const IMAGE_HEIGHT = 60

type DatasetThumbnailProps = {
  imageUrl?: string
  onPreview?: () => void
}

export const DatasetThumbnail = ({
  imageUrl,
  onPreview
}: DatasetThumbnailProps) => {
  const { hovered, ref } = useHover()

  const handleClick = () => {
    if (onPreview) {
      onPreview()
    }
  }

  return (
    <UnstyledButton ref={ref} pos="relative" onClick={handleClick}>
      <Image
        w={IMAGE_WIDTH}
        h={IMAGE_HEIGHT}
        fit="cover"
        src={imageUrl || ImagePlaceholder}
        fallbackSrc={ImagePlaceholder}
        radius="sm"
        styles={(theme) => ({
          root: {
            outline: `1px solid ${theme.colors.dark[3]}`
          }
        })}
      />

      {hovered && (
        <Overlay color="black" backgroundOpacity={0.6} blur={50} center>
          <Stack justify="center" align="center" gap={4}>
            <IconSearch size={14} color="white" />

            <Text size="xs" fw={600} c="white">
              <FormattedMessage id="datasets.list.buttons.preview" />
            </Text>
          </Stack>
        </Overlay>
      )}
    </UnstyledButton>
  )
}
